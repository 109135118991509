<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" @clickActionGroup="clickActionGroup" @clickActionGroupSecondary="clickActionGroupSecondary" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :groupActionButton="groupActionButton" :groupActionButtonLabel="groupActionButtonLabel" :groupActionButtonSecondary="groupActionButtonSecondary" :groupActionButtonSecondaryLabel="groupActionButtonSecondaryLabel" :indexSearch="indexSearch")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingUsersList || isLoadingUsersListArchived || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des utilisateurs...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-user-content(ref='sidebarUserContent' @closeSidebar="closeSidebar" @refreshGrid="refreshGrid")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarUserContent from "@/components/directory/user/SidebarUserContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarUserContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "user",
      uid: "users",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      groupActionButton: true,
      groupActionButtonLabel: "Activer le(s) compte(s)",
      groupActionButtonSecondary: true,
      groupActionButtonSecondaryLabel: "Désactiver le(s) compte(s)",
      searchPlaceholder: "Rechercher un utilisateur",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "boolean",
        field: "isActive",
        headerText: "Actif",
        width: 60,
        maxWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "activeTemplate",
        visible: true,
      },
      {
        type: "string",
        field: "civility",
        filterTemplate: "civilityFilterTemplate",
        headerText: "Civilité",
        width: 100,
        maxWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "civilityTemplate",
        visible: true,
      },
      {
        type: "string",
        field: "lastName",
        headerText: "Nom",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "firstName",
        headerText: "Prénom",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "phoneNumber",
        headerText: "Téléphone",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "secondaryPhoneNumber",
        headerText: "Tél secondaire",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "email",
        headerText: "E-mail",
        width: "auto",
        minWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "usersList",
      "usersListArchived",
      "isLoadingUsersList",
      "isLoadingUsersListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
  },
  created() {
    this.getUsers({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getUsers",
      "getUsersArchived",
      "activeUserAccounts",
      "disableUserAccounts",
    ]),
    formatCurrency,
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/new-user" });
    },
    clickActionGroup() {
      let messageTitle = "Êtes-vous sûr de vouloir activer cet utilisateur ?";
      let messageSubTitle =
        "En activant cet utilisateur, il recevra un email pour initialiser son mot de passe.";

      if (this.selectedRows.length > 1) {
        messageTitle = "Êtes-vous sûr de vouloir activer ces utilisateurs ?";
        messageSubTitle =
          "En activant ces utilisateurs, ils recevront un email pour initialiser leurs mots de passe.";
      }

      this.$bvModal
        .msgBoxConfirm(messageSubTitle, {
          title: messageTitle,
          size: "sm",
          okVariant: "warning",
          okTitle: "Activer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.activeUserAccounts({
              userIds: this.selectedRows.map((elem) => elem.id),
            });
            this.closeSidebar();
          }
        });
    },
    clickActionGroupSecondary() {
      let messageTitle =
        "Êtes-vous sûr de vouloir désactiver cet utilisateur ?";
      let messageSubTitle =
        "En désactivant cet utilisateur, il ne pourra plus se connecter sur ORISIS.";

      if (this.selectedRows.length > 1) {
        messageTitle = "Êtes-vous sûr de vouloir désactiver ces utilisateurs ?";
        messageSubTitle =
          "En désactivant ces utilisateurs, ils ne pourront plus se connecter sur ORISIS.";
      }

      this.$bvModal
        .msgBoxConfirm(messageSubTitle, {
          title: messageTitle,
          size: "sm",
          okVariant: "warning",
          okTitle: "Désactiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.disableUserAccounts({
              userIds: this.selectedRows.map((elem) => elem.id),
            });
            this.closeSidebar();
          }
        });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getUsersArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getUsers({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (this.archive) {
        this.$refs["sidebarUserContent"].getUserArchivedDetails(
          event.rowData.id,
          1
        );
      } else {
        this.$refs["sidebarUserContent"].getUserDetails(event.rowData.id);
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {},
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
