<template>
  <div v-if="isLoadingUser || isUpdatingUser || isDeletingUser" class="h-100">
    <div class="text-center flex-center h-100">
      <div class="loading-bg-inner">
        <div class="loader">
          <div class="outer"></div>
          <div class="middle"></div>
          <div class="inner"></div>
        </div>
      </div>
      <div style="margin-top: 7rem">
        Chargement des détails de l'utilisateur...
      </div>
    </div>
  </div>

  <div v-else-if="user" class="h-100">
    <div class="sidebar-header py-1 px-2">
      <div class="d-flex align-items-center">
        <div>
          <h3>{{ user.firstName + " " + user.lastName }}</h3>
        </div>
      </div>
      <feather-icon
        icon="XIcon"
        size="24"
        @click.stop="closeSidebar"
        class="close-icon cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="m-0" />

    <div class="sidebar-action py-05 px-2">
      <div class="d-flex align-items-center">
        <b-button
          v-if="archived"
          variant="warning"
          class="btn-icon"
          @click="
            restoreUserLocal(user.id, user.firstName + ' ' + user.lastName)
          "
        >
          <feather-icon icon="ArrowDownIcon" /> Restaurer
        </b-button>
        <b-button
          v-else
          @click="consultUser(user.id)"
          class="btn-icon"
          variant="primary"
        >
          <feather-icon icon="EyeIcon"> </feather-icon>
          <span style="margin-left: 3px"> Gérer</span>
        </b-button>
        <b-button
          v-if="!user.isActive"
          variant="outline-primary"
          class="btn-icon ml-1"
          @click="
            activeUserLocal(user.id, user.firstName + ' ' + user.lastName)
          "
        >
          <feather-icon icon="SendIcon" /> Activer le compte
        </b-button>
        <!-- <vs-dropdown class="cursor-pointer chip">
                      <span class="point" :class="'bg-'+user.status.class"></span>
                      <span class="label" :class="'text-'+user.status.class">{{user.status.label}}</span>
                      <vs-dropdown-menu style="z-index: 200001">
                          <span class="cursor-pointer chip"  @click.stop v-for="(status, index) in statusList" :key="index">
                              <span class="point" :class="'bg-'+status.class"></span>
                              <span class="label" :class="'text-'+status.class">{{status.label}}</span>
                          </span>
                      </vs-dropdown-menu>
                  </vs-dropdown> -->
      </div>
      <b-button-group v-if="archived">
        <b-button
          variant="outline-danger"
          class="btn-icon"
          @click="
            deleteUserLocal(user.id, user.firstName + ' ' + user.lastName)
          "
        >
          <feather-icon icon="TrashIcon" /> Supprimer définitivement
        </b-button>
      </b-button-group>
      <b-button-group v-else>
        <b-button
          variant="outline-danger"
          class="btn-icon"
          @click="
            archiveUserLocal(user.id, user.firstName + ' ' + user.lastName)
          "
        >
          <feather-icon icon="ArchiveIcon" />
        </b-button>
      </b-button-group>
    </div>

    <vs-divider class="m-0" />

    <div class="sidebar-content">
      <div v-show="user.secondaryPhoneNumber || user.phoneNumber || user.email">
        <h4 class="mb-1 text-primary">
          <feather-icon icon="PhoneCallIcon" /> Coordonnées
        </h4>
        <ul class="listTab">
          <li v-show="user.email">
            <span>Adresse e-mail</span>
            <span>{{ user.email }}</span>
          </li>
          <li v-show="user.phoneNumber">
            <span>Téléphone principal</span>
            <span>{{ user.phoneNumber }}</span>
          </li>
        </ul>
        <vs-divider />
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      archived: false,
      user: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingUser",
      "compagniesList",
      "isLoadingUser",
      "isDeletingUser",
    ]),
  },
  methods: {
    ...mapActions([
      "deleteUser",
      "isArchivingUser",
      "archiveUsers",
      "activeUser",
      "restoreUsers",
      "getUserById",
      "getUserArchivedById",
    ]),
    getUserArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.getUserArchivedById({ userId: id }).then((res) => {
          this.user = res;
        });
      } else {
        this.user = {};
      }
    },
    getUserDetails(id) {
      this.archived = false;
      if (id > 0) {
        this.getUserById({ userId: id }).then((res) => {
          this.user = res;
        });
      } else {
        this.user = {};
      }
    },
    async editUser(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-user",
        params: {
          id: id,
          title: "Edit : " + this.user.firstName + " " + this.user.lastName,
          tips:
            "Editer le user : " +
            this.user.firstName +
            " " +
            this.user.lastName,
        },
      });
    },
    async consultUser(id) {
      this.closeSidebar();
      this.$router.push({
        name: "user",
        params: {
          id: id,
          title:
            "Utilisateur : " + this.user.firstName + " " + this.user.lastName,
          tips:
            "Consulter l'utilisateur' : " +
            this.user.firstName +
            " " +
            this.user.lastName,
        },
      });
    },
    activeUserLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "En activant cet utilisateur, il recevra un email pour initialiser son mot de passe.",
          {
            title: 'Êtes-vous sûr de vouloir activer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Activer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.activeUser({ userIds: [id] }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    archiveUserLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "En archivant cet utilisateur, il ne pourra plus se connecter. Vous pourrez à tout moment restaurer cet utilisateur en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveUsers({ usersIds: [id] }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    restoreUserLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Cet utilisateur pourra se reconnecter avec ses identifiants habituels.",
          {
            title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreUsers({ usersIds: [id] }).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteUserLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteUser(id).then((res) => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>
